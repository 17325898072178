$background:#f8f8fc;
$color:#6c756e;
$dark-theme:#1a1c33;

/*Color Variables*/
$primary:#664dc9; 
$secondary:#5b73e8;
$pink:#ec82ef;
$teal:#2bcbba;
$cyan:#116e7c;
$purple:#aa4cf2;
$success:#38cb89;
$warning:#ffab00;
$danger:#ef4b4b;
$info:#3e80eb;
$orange:#fc7303;
$dark:#343a40;
$indigo:#6574cd;
$gray:#868e96;
$white:#fff;
$black:#000;

/*Border Variables*/
$border: #e6ebf1;

/*Shadow Variables*/
$shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);

/*Colors*/
$primary2:#2ec49f; 
$primary3:#0062cd;
$primary4:#f66347;
$primary5:#06bfd3;




	

